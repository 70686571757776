<template>
  <div
    class="subtitle-1 d-flex justify-center"
    :class="$vuetify.breakpoint.mdAndDown ? 'flex-column justify-center align-center' : null"
  >

    <div class="d-flex flex-column">
      <div class="pr-2">
        <v-img
          :width="$vuetify.breakpoint.mdAndUp ? $vuetify.breakpoint.mdOnly ? '250' : '400' : '300'"
          alt="Our Story"
          src="/images/book.svg"
        ></v-img>
      </div>
      <div
        v-if="$vuetify.breakpoint.lgAndUp"
        style="padding-top: 110vh"
      >
        <v-img
          v-if="$vuetify.breakpoint.mdAndUp"
          :width="$vuetify.breakpoint.mdAndUp ? $vuetify.breakpoint.mdOnly ? '250' : '400' : '300'"
          alt="Our Story"
          src="/images/Our Focus.svg"
        ></v-img>
      </div>
    </div>

    <div :style="$vuetify.breakpoint.lgAndUp ? 'width: 50%' : $vuetify.breakpoint.md ? 'width: 70%' : $vuetify.breakpoint.xsOnly ? 'width: 90%' : 'width: 80%'">
      <div class="py-8 text-h2 font-weight-medium secondary--text">About Us</div>
      <div class="pb-6">
        Welcome to Smit & Kie Brokers (Pty) Ltd, a testament to over three decades of dedication and expertise in the insurance industry.
      </div>
      <div class="pb-6">
        Smit & Kie Brokers (Pty) Ltd is a national-class short term insurance brokerage with delegated outsource & binder powers
        from national Insurance companies. Since inception in 1999 Koos & Riekie had progressively developed the company’s
        administrative infrastructure to allow small & medium sized independent brokers that operates across South Africa, to
        offer their client’s excellent product design, competitive pricing, efficient policy administration & claims settlement.
        The broker now can focus on what he does best i.e. giving advice to clients. Relieving him/her from policy administration,
        compliance and accounting issues.
      </div>
      <div class="pb-6">
        SMIT & KIE Brokers is proud to do the administration of 10 000 plus clients under which there are several of the country’s
        mega farmers as well as large road & construction companies, factories, general mining, commercial buildings & businesses,
        hotels, lodges, branches of national chain stores, transport companies and domestic policies.
      </div>

      <div class="py-8 text-h2 font-weight-medium secondary--text">Who Are We</div>
      <div class="pb-6">
        <div>
          Founders Sandy Guthrie and Koos Smit began their distinguished careers in insurance in 1984 and 1987, respectively. Their
          paths crossed in 1999, leading to the inception of our short-term brokerage, bolstered by delegated authorities from major
          insurers. Over the next 25 years, Sandy and Koos built a robust brokerage and administration house with a national footprint,
          empowering small to medium short-term brokers with unparalleled access to extensive experience and significant buying power,
          enabling the placement of larger risks with insurers.
        </div>
      </div>
      <div class="pb-6">
        Today, our company continues to thrive, driven by our founders' vision. Sandy and Koos have transitioned to focus on
        acquiring new entrants and partnering with retiring insurance brokers, forming a strategic alliance with Bertus Visser.
        With 38 years of experience in the corporate insurance market, Bertus brings additional depth and expertise to our team.
      </div>
      <div class="pb-6">
        Our commitment remains steadfast: to provide comprehensive support and exceptional services to our clients, leveraging
        our collective experience and strategic partnerships to navigate the ever-evolving insurance landscape.
      </div>
      <div class="pb-6">
        Our CEO is Nadine Du Plessis a qualified Chartered accountant and the daugther of Koos Smit with 10 years experience in
        the administration side of insurance, assisting Nadine is Warren Bennett the COO with his extensive experience in managements
        of claims handeling and broker books.
      </div>
      <div class="pb-6">
        Warren Bennett, our COO, supports Nadine with his extensive experience in the management of claims handling and broker books.
        Warren's expertise ensures that our operational processes are seamless and that our clients receive the best possible service.
      </div>
      <div class="pb-6">
        Our commitment remains steadfast: to provide comprehensive support and exceptional services to our clients, leveraging
        our collective experience and strategic partnerships to navigate the ever-evolving insurance landscape.
      </div>
      <div class="pb-3">
        We are making use of Tasklogix a tailor-made CRM allowing any brokerage to implement processes to suit each individual
        need. This then gives Smit & Kie the leverage to employ competent personel who can apply their administrative skills from
        anywere to assist the growing list of new brokers.
      </div>

      <div
        v-if="$vuetify.breakpoint.mdAndDown"
        class="d-flex justify-center align-center"
      >
        <div style="width: 50%">
          <v-img
            alt="Our Story"
            src="/images/Our Focus.svg"
          ></v-img>
        </div>
      </div>

      <div class="py-6 text-h3 font-weight-medium secondary--text">Divisions</div>
      <div class="pb-10">
        <span class="font-weight-bold">SMIT & KIE Brokers</span> consists of Short term Insurance Broking and the administration thereof on behalf of Leading Insurance companies. These brokers operate on their own FSP.
      </div>
      <div class="pb-10">
        <span class="font-weight-bold">SMIT ADVISORS cc</span> completely separated from Smit & Kie Brokers provide advice on a range of Life Insurance, Investment & Medical aid
        needs and has a non-compete agreement with all Smit & Kie Brokers & affiliated independent brokers.
      </div>

      <div class="pt-8 pb-5 text-h2 font-weight-medium secondary--text">Our Focus</div>
      <div class="pb-5">
        We focus on being the one stop shop for the broker on all short term insurance
      </div>
      <div
        class="pb-6 d-flex flex-wrap"
        :class="$vuetify.breakpoint.mdAndDown ? 'flex-column' : null"
      >
        <div
          v-for="(item, i) in ourFocusArr"
          :key="i + 'A'"
          class="pb-5"
          :class="item.type === 'image' ? 'd-flex justify-center align-center' : null"
          :style="$vuetify.breakpoint.mdAndDown ? 'width: 100%' : 'width: 50%'"
        >

          <div v-if="item.title">
            <div class="pb-2 text-h5 font-weight-regular">{{ item.title }}</div>
            <div
              v-for="(point, k) in item.pointsArr"
              :key="k + 'A'"
            >
              <v-icon
                x-small
                class="px-2"
                color="secondary"
              >mdi-circle</v-icon>
              {{ point }}
            </div>
          </div>

          <div
            v-else
            class="d-flex justify-center align-center full-height"
            :style="$vuetify.breakpoint.md ? 'width: 50%' : $vuetify.breakpoint.smAndDown ? 'width: 60%' : 'width: 80%'"
          >
            <v-img
              alt="Our Story"
              src="/images/Our Focus Circle.png"
            ></v-img>
          </div>

        </div>
      </div>

      <div class="py-8 text-h2 font-weight-medium secondary--text">Why Us</div>
      <div class="pb-6">
        Smit and Kie Brokers acts with the utmost level of honesty and integrity when providing professional and accurate advice,
        by managing your risks with products according to the latest market trends supported by qualified and competent staff members.
      </div>
      <div class="pb-6">
        We provide you with the most appropriate possible cover money can buy with the most affordable premiums adding our world
        class service. We place your needs first, as we always value you the client as the most important asset of our business.
      </div>
      <div class="pb-6">
        We operate strictly according to legislation as prescribed by government and keep updated with the latest changes in legislation
        to ensure that we always comply. Therefore your personal information and interests is protected at all times.
      </div>
      <div class="pb-10">
        We strive towards making a difference towards our clients by walking the extra mile during claim stage. We understand the
        importance of settling claims as quickly as possible by looking for reasons to settle your claims in the most convenient manner possible.
      </div>

    </div>

    <div
      v-if="$vuetify.breakpoint.lgAndUp"
      style="padding-top: 100vh"
    >
      <v-img
        :width="$vuetify.breakpoint.mdAndUp ? $vuetify.breakpoint.mdOnly ? '250' : '400' : '300'"
        alt="Our Story"
        src="/images/growth.svg"
      ></v-img>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      ourFocusArr: [
        {
          type: 'text',
          title: 'Representative Role',
          pointsArr: [
            'Introduction Letter',
            'Establish a Relationship with Client/Service Arrangement',
            'Fact Find & Financial Needs',
            'Analyses',
            'Proposal & Decision Making',
            'Keep Record of Advise',
            'Implement and Review',
            'Keep Registers'
          ]
        },
        {
          type: 'text',
          title: 'Key Individual Role',
          pointsArr: [
            'Manage Brokerage',
            'Manage Fit & Proper Status of Brokers (Reps)',
            'Risk Management Plan',
            'Compliance Registers',
            'Templates/Processes and Record Keeping',
            'Under Supervision'
          ]
        },
        {
          type: 'text',
          title: 'Owner Role',
          pointsArr: [
            'Business Entity',
            'Business Plan (Exco/Shareholders meetings)',
            'Business Hardware/Software',
            'Business Brand',
            'Business Partners (P/P’s)',
            'Value Proposition',
            'Succession Plan'
          ]
        },
        {
          type: 'image',
        }
      ]
    }
  }
}
</script>